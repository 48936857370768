.CarouselImg{
    height: 230px;
}
.CarouselTopST{
    z-index: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 3px solid rgba(0, 128, 0, 0.44);
    box-shadow: rgba(45, 35, 66, 0.4) 0px 0px 8px,rgba(45, 35, 66, 0.3) 8px 8px 8px -3px;
}
.Stories{
    padding-left: 2px;
    
}
.Text1{
    margin: 0;
    font-size: 14sp;
}



@media only screen and (min-width:576px) {
    .CarouselImg{
        height: 430px;
        width: 80vw;
    }
    .CarouselTopST{
        width: 90vw;
        margin: auto;
    }
    .Stories{
        padding-left: 5vw;
    }
}

@media (max-width: 780px){
    .CarouselTopST{
        border: none;
    }
}