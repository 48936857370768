@import url('https://fonts.googleapis.com/css2?family=Alata&family=Concert+One&display=swap');
.HomeBody{
    height: 100vh;
    padding-top: 60px;
}
a{
    text-decoration: none;
}
#Home{
    margin-top: 30px;
    /* margin-bottom: 80px; */
}
.mobileExpand{
    display: none;
}
#Products,#ContactUs{
    padding-top: 10px;
}
.welcomeDiv{
    width: 90%;
    height: 700px;
    margin: 90px 20px 20px 20px;
    display: flex;
    justify-content: space-around;
}

.textDivOuter{
    font-size: 34px;
    text-align: center;
    top: 20px;
    left: 120px;
    height: 380px;
    border-bottom: 2px solid green;
    border-top: 2px solid green;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.hideDiv{
    overflow: hidden;
    font-family: Altas;
    margin-top: 10px;
    margin-bottom: 10px;
}
.shopName{
    color: green;
    font-family: Concert One;
    font-size: 48px;
    animation-delay: 2s;
}
.hideDiv span{
    display: inline-block;
}
.outerSideLeaf{
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
}
.sideLeaf,.sidePalmLeaf{
    position: absolute;
}
.sideLeaf{
    right: 0;
    top: 70px;
    opacity: 0.3;
}
.sidePalmLeaf{
    transform: rotate(-60deg) rotateX(180deg);
    left: 80px;
    top: 290px;
    opacity: 0.5;
}
.sideFloridaLeaf{
    display: none;
}
.textAnimatefrom{
    transform: translateY(100%);
}
.textAnimateto{
    animation: textAnimation 1s;
}
.weOfferText{
    font-size: 26px;
    padding-bottom: 4px;
}
.CompanyCardsDiv{
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 400px;
    height: 700px;
    position: absolute;
    display: flex;
    justify-content:space-around;
    flex-direction: column;
    transition: 700ms ease-in-out;
}
.companyCardsOuterDiv{
    width: 400px;
    height: 780px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-top: 2px solid green;
    border-bottom: 2px solid green;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    overflow: hidden;
}
.saleBtnContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.divVisible{
    transform: translateX(0);
    transition-delay: 700ms;
    transition: 700ms ease-in;
}
.divInvisibleW{
    transform: translateX(700px);
    transition: 700ms ease-out;
}
.divInvisibleR{
    transform: translateX(-700px);
    transition: 700ms ease-out;
}
.button-30 {
    align-items: center;
    appearance: none;
    background-color: #BAD7D7;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 2px,rgba(45, 35, 66, 0.3) 0 2px 2px -3px;
    box-sizing: border-box;
    color: black !important;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono",monospace;
    height: 40px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
  }
  
  .button-30:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  }
  
  .button-30:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(0, 128, 0, 0.577) 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-30:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
  }
  .button-30-click{
    opacity: 0.5;
    border-bottom: 3px solid green !important;
  }
.companyCard{
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #C3DFDF;
    position: relative;
    box-shadow:  9px -9px 18px #b7d2d2,
                -9px 9px 18px #cfecec;
}
.companyCardName{
    position: absolute;
    bottom: -25px;
    height: 25px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #C3DFDF;
    border-bottom: 2px solid green;
    border-left: 2px solid green;
    border-right: 2px solid green;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
/* .companyCardBorder{
    border-bottom: 1px solid green;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
} */
.companyCardLogo{
    height: 60px;
    width: 140px;
}
.companyCardLogoPSG{
    height: 80px;
    width: 150px;
}

@keyframes textAnimation {
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(0%);
    }
}

@keyframes textBoxAnimation {
    0%{
        width: 900px;
    }
    100%{
        width: 600px;
    }
}




@media (max-width: 780px){
    .shopName{
        font-size: 34px;
    }
    .weOfferText{
        font-size: 16px;
    }
    .hideDiv{
        margin: 0;
    }
    .sidePalmLeaf{
        display: none;
    }
    .sideLeaf{
        top: 280px;
        opacity: 0.6;
    }
    .sideFloridaLeaf{
        display: block;
        position: absolute;
        transform: rotateY(180deg) rotate(-40deg);
        top: -200px;
        left: -10px;
    }
    .textDivOuter{
        font-size: 24px;
        margin-bottom: 40px;
        padding: 20px 5px 20px 5px;
        justify-content: center;
    }
    .companyCard{
        width: 100%;
    }
    .CompanyCardsDiv{
        width: 100%;
    }
    .companyCardsOuterDiv{
        width: 100%;
    }
  }
  @media (max-width: 1230px){
    .welcomeDiv{
        flex-direction: column;
        height: fit-content;
    }
    .CompanyCardsDiv{
        margin: 10px auto 10px auto;
    }
    .companyCardsOuterDiv{
        margin: 10px auto 10px auto;
    }
  }