.Desktop{
    display: flex;
    justify-content: space-between;
    padding: 30px 10px 4px 10px;
    height: 63px;
    background-color: #BAD7D7;
    z-index: 3;
    margin-bottom: 50px;
}
.Nav{
    width: 550px;
    display: flex;
    justify-content: space-around;
}
.NavItems{
    margin: auto;
}
.NavItemstag{
    margin: auto;
    font-size: 18px;
    font-weight: 400;
    padding: 7px;
}
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #BAD7D7;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 2px,rgba(45, 35, 66, 0.3) 0 2px 2px -3px;
  box-sizing: border-box;
  color: black !important;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(0, 128, 0, 0.577) 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}
.Logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.logoImg{
    height: 60px;
    width: 180px;
}
.logoImgSmall{
    position:static;
    top: -2px;
    left: 20px;
    height: 40px;
    width: 180px;
}
.Sticky{
    position: sticky;
    top: 0;
    width: 100%;
    height: 63px;
    padding-top: 4px !important;
    box-shadow: #899f9f 0px 5px 5px;
}

.phone {
    width: 100vw;
    height: 100px;
    margin: auto;
    display: flex;
    align-items: flex-end;
    position: fixed;
    bottom: 0px;
    justify-content: center;
    z-index: 20;
  }
.phone::before {
    content: '';
    position: absolute;
    width: 84%;
    height: 0px;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
}
.phone::after {
    content: '';
}
.phone_content {
    filter: contrast(30);
    width: 100%;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    position: absolute;
    border-radius: 15px 15px 15px 15px;
}
.phone_bottom {
    width: 100%;
    height: 46px;
    background: black;
    display: flex;
    justify-content: center;
    filter: blur(10px);
}
  
input {
    display: none;
}
  
.navLabel {
    cursor: pointer;
    display: flex;
    width: 33%;
    height: 40px;
    position: relative;
    z-index: 2;
    align-items: center;
    justify-content: center;
}
.showLabel{
    color: #FFFFFF;
    margin-top: 15px;
    transition: 500ms ease-out;
    
}
.hideLabel{
    transform:translateY(100px);
    transition: 500ms ease-in;
}
.navLabel > img {
    width: 35px;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    z-index: 3;
    transition: 200ms 100ms cubic-bezier(0.14, -0.08, 0.74, 1.4);
}
.navLabel::before {
    content: '';
    position: absolute;
}
  
.circle {
    width: 50px;
    height: 50px;
    background: black;
    position: absolute;
    top: 25px;
    z-index: 1;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transition: 200ms cubic-bezier(0.14, -0.08, 0.74, 1.4);
    box-shadow: 0px 32px 40px 0px rgba(128, 128, 128, 0.29);
}
  
.indicator {
    width: 70px;
    height: 70px;
    background-image: linear-gradient(0deg, #B9D7D7, rgba(183, 255, 154, 0)), linear-gradient(0deg, #e6ffffac, rgba(183, 255, 154, 0)), linear-gradient(0deg, #e2fffe, rgba(183, 255, 154, 0));
    background-size: cover;
    background-position: 0 10px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: -42px;
    right: 0;
    margin: auto;
    transition: 200ms cubic-bezier(0.14, -0.08, 0.74, 1.4);
}
  
  #s1:checked ~ [for="s1"] > img {
    top: -60px;
  }
  #s1:checked ~ .circle,
  #s1:checked ~ div div .indicator {
    left: -65%;
  }
  
  #s2:checked ~ [for="s2"] > img {
    top: -60px;
  }
  #s2:checked ~ .circle,
  #s2:checked ~ div div .indicator {
    left: 0;
  }
   
  #s3:checked ~ [for="s3"] > img {
    top: -60px;
  }
  #s3:checked ~ .circle,
  #s3:checked ~ div div .indicator {
    left: 66%;
  }


  @media (min-width: 780px){
    .phone{
        display: none;
    }
  }
  @media (max-width: 780px){
    .Nav{
        display: none;
    }
    .logoImg{
        margin: auto;
        left: -10px;
        position: relative;
        top: -15px;
    }
    .Desktop{
        height: 70px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        justify-content: center;
        box-shadow: #899f9f 0px 5px 5px;

    }
    .Sticky{
        position: relative;
    }
  }
  