.imageBerger{
    width: 96vw;
    height: 84vh;
    margin: auto;
}
.imageBergerDiv{
    margin-top: 100px;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: rgba(72, 50,133,0.4);

}
.imgDivBerger{
    position: relative;
    width: 96vw;
    height: 84vh;
}
.imgDivBerger::after{
    margin: auto;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 96vw;
    height: 84vh;
    box-shadow: inset 0 0 10px 10px rgba(72, 50,133,0.6);
}
.imgDivBergerOuter{
    width: 96vw;
    height: 84vh;
    position: relative;
    margin: auto;
}
.comingSoonTextDiv{
    position: absolute;
    top: 30px;
    left: 26vw;
    
}
.comingSoonText{
    font-size: 40px;
    color: rgba(72, 50,133,1);
    font-family: 'Times New Roman', Times, serif;
    font-weight: 800;
}
.bergerLogo{
    height: 200px;
    width: 300px;
}

@media only screen and (max-width:780px) {
    .imageBerger{
        width: 100vw;
        height: 50vh;
        margin: auto;
    }
    .imgDivBerger{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .imgDivBerger::after{
        width: 100vw;
        height: 50vh;
        box-shadow: inset 0 0 10px 10px rgba(72, 50,133,0.4);
    }
    .imageBergerDiv{
        height: 60vh;
    }
    .imgDivBergerOuter{
        width: 100vw;
        height: 50vh;
    }
    .bergerLogo{
        display: none;
    }
}