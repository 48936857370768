.productsPage{
    height: fit-content;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 50px;
}
.producCardsContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* padding-left: 30px;
    padding-right: 30px;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    place-items: center;
    gap: 50px 100px; */
}
.productCard{
    height: 260px;
    width: 350px;
    margin: 30px 20px 30px 20px;
    display:block;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    transition: 500ms ease-in;
    border-radius: 0px;
    background: #D8F2F2;
    box-shadow:  -8px 8px 16px #bed5d5,
             8px -8px 16px #f2ffff;
}
.productCard:hover{
    height: calc((310px + var(--order) * 80px));
    border-bottom: 3px solid rgb(99, 28, 28);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    
}
.productCard:hover .productInfo{
    border-top: 3px solid green;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
}
.productTitle{
        width: 350px;
        align-items: center;
        height: 260px;
        background: #D8F2F2;
        text-align: center;
        color: green;
        font-weight: 600;
}
.productToolKit{
    display: none;
}
.productToolKitContainer{
    display: none;
}
.productTitleInfo{
    color: rgb(82, 82, 82);
    font-weight: 400;
    padding: 3px;
    
}
.productInfo{
    width: 350px;
    height: calc((300px + var(--order) * 80px));;
    background: #D8F2F2;
    box-shadow: inset -10px 10px 13px #bed5d5,
            inset 10px -10px 13px #f2ffff;
    text-align: center;
    padding-top: 15px;
}

.productImgInner{
    margin: 5px;
    height: 90px;
    width: 100px;
}
.subProductCard{
    display: flex;
    width: calc(100% - 25px);
    height: 70px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content:space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    background: linear-gradient(225deg, #e7ffff, #c2dada);
    box-shadow:  -6px 6px 15px #cde6e6,
                6px -6px 15px #e3fefe;
}

.subProductCard:hover{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 50px;
    height: 50px; */
}
.subProductCard:hover .subProductImg{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 330px;
    height: 330px;
    top: 75px;
    left: 0;
    right: 0;
    bottom: auto;
    margin: auto;
    background-color: #8fb9b965;
    border-radius: 25px;
    z-index: 5;
}
.subProductCard:hover .subProductName{
    color: green;
    font-weight: 500;
}
.subProductImg{
    margin: 5px;
    height: 60px;
    width: 60px;
}


@media (max-width: 780px){
    .productCard{
        width: 100%;
        height: 295px;
    }
    .productTitle{
        width: 100%;
        height: 295px;
    }
    .productInfo{
        width: 100%;
    }
    .subProductCard{
        width: calc(100% - 15px);
    }
    /* .producCardsContainer{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 0;
    } */
    .productToolKit{
        display: block;
        color: #D8F2F2;;
        width: 50%;
        background-color: rgba(165, 42, 42, 0.842);
        border-top-right-radius: 14px;
        border-top-left-radius: 14px;
    }
    .productToolKitContainer{
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .productCard:hover .productToolKitContainer{
        display: none;
    }
    .doubleArrow{
        padding-left: 5px;
        color: rgb(159, 255, 159);
    }
    
    .productCard:hover{
        height: calc((340px + var(--order) * 80px));
    }
    .subProductCard:hover .subProductImg{
        width: 300px;
        height: 300px;
        top: 20px;
        bottom: auto;
        
    }
}